import React, { Component } from 'react';
import { connect } from 'react-redux';
import {multiverseUrl,blockURL} from '../config.js'
import $ from 'jquery';

class Height extends Component {
  constructor(){
    super();
    this.state = {oldHeight:"SYNCING",open:false};
  }
  blockURLNRG(){
    return '/#/blocks/latest'
  }
  componentDidUpdate(){
    if(this.props.bcHeight !== this.state.oldHeight){
      let element = document.getElementById("bcheight");
      if(element){
        element.classList.remove("fadein");
        void element.offsetWidth;
        element.classList.add("fadein");
      }
      this.setState({oldHeight:this.props.bcHeight});
    }
  }
  onMouseEnter(){
    this.setState({open:true})
  }
  onMouseLeave(){
    this.setState({open:false})
  }
  renderChildBlocks(){
    return this.props.childBlocks.map(({blockchain,height,hash})=>{
      return (
        <a key={hash} style={{border:'1px solid grey',borderTop:'none',borderRight:'none',padding:0,display:'block'}} className='col s12' href={`${blockURL[blockchain.toUpperCase()]}${height}`} rel="noopener noreferrer" target="_blank">
          <div className='childImg' style={{height:'30px'}}>
            <img src={`./${blockchain}.png`}  style={{height:'30px',marginTop:'5px'}} />
          </div>
          <div className='childHeight'>
            {height}
          </div>
        </a>
      )
    })
  }
  render() {
    let color = this.props.disconnected ? 'grey' : 'white';
    let display = this.state.open ? '' : 'none';
    return (
      <li onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} style={{width:'100px'}}>

        <a href={this.blockURLNRG()} style={{color,height:'43px',padding:0}} className='rightLink'>
          <div className='childImg' style={{height:'30px'}}>
            <img src={`./overline.png`}  style={{height:'30px',marginTop:'5px'}} />
          </div>
          <div className='childHeight' style={{paddingBottom:0,lineHeight:'43px',paddingTop:0}}>
            {this.props.bcHeight}
          </div>
        </a>

        <div style={{display,width:'100%',background:'rgba(39, 39, 39)'}}>
          {this.renderChildBlocks()}
        </div>

      </li>
    )
  }
}

function mapStateToProps(state,props) {

  let childBlocks = [];
  let latestBlock = state.socket.latestBlock;
  if(latestBlock && latestBlock.blockchainHeaders){
    Object.keys(latestBlock.blockchainHeaders).map(key=>{
      latestBlock.blockchainHeaders[key].forEach((block)=>{
        childBlocks.push(block)
      })
    })
  }

  return {
    childBlocks,
    block: state.block.latest,
    bcHeight: state.block.latest ? state.block.latest.height : 'SYNCING',
    disconnected: !state.socket.connected
  };
}

export default connect(mapStateToProps, {})(Height);

import RpcClient from 'bcjs/dist/client'
import Wallet from 'bcjs/dist/wallet'

export default async function callBC({hostname,port,scookie},method,params) {
  var body = JSON.stringify({
      jsonrpc: '2.0',
      id: Math.round(Math.random()*10000),
      method: method,
      params
  })

  let auth = btoa(`:${scookie}`);
  let url = `${hostname}/rpc`;
  let headers = { 'Content-Type': 'application/json',"Authorization":`Basic ${auth}` }
  let response = await fetch(url, {method: 'post', headers,body})
  let data = await response.json()
  return data
}


export function createClient({ hostname, port, scookie }) {
  const url = `https://${hostname}`
  return new RpcClient(url, scookie);
}

export async function sendTx({ hostname, port, scookie }, tx) {
  const client = createClient({ hostname, port, scookie })
  return client.sendTx(tx)
}

export function createWallet({ hostname, port, scookie }){
  const client = createClient({ hostname, port, scookie })
  return new Wallet(client)
}

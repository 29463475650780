export const mainnet_url = 'https://mainnet.infura.io/v3/ca4c368803c347699a5d989cd367c0a6';
export const multiverseUrl = "http://explorer.overline.network/#/blocks/latest"

//PAIRS
export const assets = ['NRG','EMB','L-EMB','USDT', 'DAI','BTC','ETH','NEO','WAV','LSK']

export const txURL = {
  // NRG:'http://borderless.ngrok.io/#/tx/',
  EMB:'https://etherscan.io/tx/',
  BTC:'https://blockchair.com/bitcoin/transaction/',
  ETH:'https://etherscan.io/tx/',
  DAI:'https://etherscan.io/tx/',
  USDT:'https://etherscan.io/tx/',
  NEO:'https://neoscan.io/transaction/',
  WAV:'https://wavesexplorer.com/tx/',
  LSK:'https://explorer.lisk.io/tx/'
}

export const blockURL = {
  // NRG:'http://multiverse.blockcollider.com/block/',
  BTC:'https://blockchair.com/bitcoin/block/',
  ETH:'https://etherscan.io/block/',
  NEO:'https://neoscan.io/block/',
  WAV:'https://wavesexplorer.com/blocks/',
  LSK:'https://explorer.lisk.io/block/'
}

import React, { Component } from "react";
import callBC from "../reducers/search/bc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../reducers/tx/actions";

/* Make sure to clean up image paths and href links */

const FIELD_STYLE = {
  fontSize: "1.5rem",
  fontFamily: "Gilmer",
  marginLeft: "10px",
  color: "black",
  left: 0,
  margin: 0,
};

const FIELD_STYLE2 = {
  fontFamily: "Gilmer",
  fontSize: "10px",
  marginLeft: "10px",
  color: "black",
};

const BC_LOGO = {
  height: 30,
  width: 30,
};

function numberWithCommas(x) {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "";
}

class FooterContainer extends Component<*> {
  constructor() {
    super();
    this.state = { nrg: "" };
  }
  async getNRG(location) {
    console.log("caling", location);
    let nrg = await callBC(location, "getNrgSupply", []);
    console.log({ nrg });
    if (nrg && nrg.result && nrg.result.amount)
      this.setState({ nrg: nrg.result.amount.split('.')[0] });
  }
  componentWillReceiveProps(nextProps: Object) {
    if (nextProps.location != this.props.location) {
      this.getNRG(nextProps.location);
    }
  }
  async componentDidMount(){
    let _this = this;
    await _this.getNRG(_this.props.location);
    setInterval(async ()=>{
      await _this.getNRG(_this.props.location);
    },10000);
  }
  render() {
    return (
      <footer style={{ bottom: "0", width: "100%" }}>
        {/* Need to specify image path */}
        <div className="main-footer">
          <div className="footer-grid">
            <div style={{ width: "180px" }}>
              <a href="/#/blocks/latest" style={FIELD_STYLE}>
                {/* <img
                src="./BCMultiverse-White-Grey.png"
                className="footer-logo"
                style={{ height: 40 }}
              /> */}
                <a href="/#/" style={FIELD_STYLE}>
                  <img
                    src="./over.png"
                    style={{ width: 30, marginRight: 10 }}
                  />
                </a>
                <a href="/#/" style={FIELD_STYLE}>
                  <img src="./overlineexplorer.png" className="multiverse-logo" />
                </a>
              </a>
              <br />
              <p
                className="smartphone-hidden"
                style={{
                  color: "white",
                  fontSize: "10px",
                  marginTop: "20px",
                  marginBottom: "0px",
                  fontFamily: "Gilmer-Light",
                  letterSpacing: "0.4px",
                  lineHeight: "15px",
                }}
              >
                300,000,000 EMB Total <br /> 100,000,000 EMB Circulating
              </p>

              <p
                className="smartphone-hidden"
                style={{
                  color: "white",
                  fontSize: "10px",
                  marginTop: "20px",
                  marginBottom: "0px",
                  fontFamily: "Gilmer-Light",
                  letterSpacing: "0.4px",
                  lineHeight: "15px",
                }}
              >
                9,800,000,000 OL Total <br />{" "}
                {numberWithCommas(this.state.nrg)} OL Circulating
              </p>
            </div>
            {/* <img
              src={BorderlessLogoWhite}
              alt="Borderless Services Inc."
              className="footer-logo"
              width="323"
              height="40"
            /> */}
            <div className="footer-gap"></div>
            <div>
              <div className="footer-title">DISCOVER</div>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://docs.overline.network/docs/borderless-faq-docs"
              >
                FAQ
              </a>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://overline.network"
              >
                Overline Chain
              </a>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://docs.overline.network/docs/beginners-guide-to-mining-overline"
              >
                Mining
              </a>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://docs.overline.network/docs/emblem-leasing"
              >
                Emblem Leasing
              </a>
            </div>
            <div className="footer-gap"></div>
            <div>
              <div className="footer-title">DEVELOPER</div>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://docs.overline.network/docs/rpc-api"
              >
                API
              </a>
              {/*} <a
                className="footer-link borderless-link-underlined-footer"
                href="https://borderless.blockcollider/SDK"
              >
                SDK
  </a> */}
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://docs.overline.network/"
              >
                Documentation
              </a>
            </div>
            <div className="footer-gap"></div>
            <div>
              <div className="footer-title">COMMUNITY</div>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://twitter.com/@overlinenetwork"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://t.me/overlinenetwork/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telegram
              </a>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://blog.overline.network/"
              >
                Medium
              </a>
              <a
                className="footer-link borderless-link-underlined-footer"
                href="https://www.embnation.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                EMB Nation
              </a>
            </div>
            <div className="footer-gap"></div>
          </div>
        </div>
      </footer>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const Footer = connect((state, ownProps) => {
  return {
    location: state.socket.location,
  };
}, mapDispatchToProps)(FooterContainer);

export default Footer;
